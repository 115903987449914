import { filter, map, tap } from 'rxjs';
import { computed, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { Logger } from '@logic-suite/shared/logger/logger.service';

interface RouterServiceState {
  currentUrl: string;
  queryParams: Params | null;
}

@Injectable({ providedIn: 'root' })
export class RouterService {
  logger = inject(Logger);
  router = inject(Router);
  activatedRoute = inject(ActivatedRoute);

  // Sources
  currentUrl$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map((navigationEnd) => navigationEnd.urlAfterRedirects),
    tap((url) => this.logger.debug(`Navigated to`, 'RouterService', url)),
  );

  queryParams$ = this.activatedRoute.queryParams.pipe(
    tap((params) => this.logger.debug(`Current queryParams`, 'RouterService', params)),
  );

  // State
  private readonly state = signal<RouterServiceState>({
    currentUrl: '',
    queryParams: null,
  });

  // Selectors
  currentUrl = computed(() => this.state().currentUrl);
  queryParams = computed(() => this.state().queryParams);

  constructor() {
    // Reducers
    this.currentUrl$.pipe(takeUntilDestroyed()).subscribe((currentUrl) => {
      this.state.update((state) => ({
        ...state,
        currentUrl,
      }));
    });

    this.queryParams$.pipe(takeUntilDestroyed()).subscribe((queryParams) => {
      this.state.update((state) => ({
        ...state,
        queryParams,
      }));
    });
  }
}
